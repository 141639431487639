import React from 'react';
import useStyles from './styles';

const UKFlag = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={useStyles().jobDoneIcon} {...props}>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.1618 0.790039H7.83932C3.95237 0.790039 0.790039 3.95198 0.790039 7.83855V14.1618C0.790039 18.0484 3.95237 21.2103 7.83932 21.2103H14.1618C18.0484 21.2103 21.2104 18.0484 21.2104 14.1618V7.83855C21.2104 3.95198 18.0484 0.790039 14.1618 0.790039Z' fill='white' />
      <path d='M21.2103 9.1671V12.8332H12.8331V21.2104H9.16698V12.8332H0.789795V9.1671H9.16698V0.789917H12.8331V9.1671H21.2103Z' fill='#FF337A' />
      <path d='M19.5787 18.6671C19.3033 18.998 18.9982 19.3031 18.6674 19.5785L13.9292 14.8403V13.929H14.8406L19.5787 18.6671Z' fill='#FF337A' />
      <path d='M8.07141 7.1601V8.07146H7.16004L2.42188 3.3333C2.6973 3.00287 3.00281 2.69732 3.33367 2.42236L8.07141 7.1601Z' fill='#FF337A' />
      <path d='M19.5792 3.33331L14.8406 8.07147H13.9292V7.1601L18.6674 2.42151C18.9982 2.69737 19.3033 3.00245 19.5792 3.33331Z' fill='#FF337A' />
      <path d='M8.07141 13.929V14.8403L3.33367 19.5785C3.00281 19.3031 2.6973 18.9975 2.42188 18.6671L7.16004 13.929H8.07141Z' fill='#FF337A' />
      <path d='M1.76341 4.26843C1.1453 5.31614 0.790039 6.53649 0.790039 7.83844V8.07155H5.6138L1.76341 4.26843Z' fill='#6787F5' />
      <path d='M0.790039 13.929V14.1619C0.790039 15.463 1.14488 16.6827 1.76221 17.7299L5.61277 13.929H0.790039Z' fill='#6787F5' />
      <path d='M20.236 17.7344C20.8549 16.6862 21.2107 15.465 21.2107 14.1619V13.929H16.3857L20.236 17.7344Z' fill='#6787F5' />
      <path d='M21.2103 8.07173V7.83863C21.2103 6.53753 20.8555 5.31786 20.2382 4.27063L16.3875 8.07173H21.2103Z' fill='#6787F5' />
      <path d='M13.9287 5.61281L17.7299 1.76216C16.6826 1.14483 15.4629 0.790039 14.1618 0.790039H13.9288L13.9287 5.61281Z' fill='#6787F5' />
      <path d='M8.07168 16.3876L4.27075 20.238C5.3182 20.8554 6.53808 21.2103 7.83943 21.2103H8.07168V16.3876Z' fill='#6787F5' />
      <path d='M13.929 16.3855V21.2104H14.162C15.465 21.2104 16.6862 20.8546 17.7344 20.2357L13.929 16.3855Z' fill='#6787F5' />
      <path d='M8.0714 5.61388V0.790039H7.83915C6.53689 0.790039 5.31623 1.14539 4.26831 1.76354L8.0714 5.61388Z' fill='#6787F5' />
    </svg>
  </span>
);

export default UKFlag;
