import React from 'react';
import useStyles from './styles';

const GlobeEarth = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={useStyles().jobDoneIcon} {...props}>
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z' fill='#7F8897' />
      <path fillRule='evenodd' clipRule='evenodd' d='M8 3C8.55228 3 9 3.44772 9 4V6C9 6.55228 9.44772 7 10 7H11C12.6569 7 14 8.34315 14 10C14 10.5523 14.4477 11 15 11C15.5523 11 16 10.5523 16 10C16 8.34315 17.3431 7 19 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H19C18.4477 9 18 9.44772 18 10C18 11.6569 16.6569 13 15 13C13.3431 13 12 11.6569 12 10C12 9.44772 11.5523 9 11 9H10C8.34315 9 7 7.65685 7 6V4C7 3.44772 7.44772 3 8 3ZM2 12C2 11.4477 2.44772 11 3 11H5C6.65685 11 8 12.3431 8 14C8 14.5523 8.44772 15 9 15C10.6569 15 12 16.3431 12 18V20C12 20.5523 11.5523 21 11 21C10.4477 21 10 20.5523 10 20V18C10 17.4477 9.55228 17 9 17C7.34315 17 6 15.6569 6 14C6 13.4477 5.55228 13 5 13H3C2.44772 13 2 12.5523 2 12ZM14 18C14 16.3431 15.3431 15 17 15H20C20.5523 15 21 15.4477 21 16C21 16.5523 20.5523 17 20 17H17C16.4477 17 16 17.4477 16 18V20C16 20.5523 15.5523 21 15 21C14.4477 21 14 20.5523 14 20V18Z' fill='#7F8897' />
    </svg>
  </span>
);

export default GlobeEarth;
