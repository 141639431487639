import React from 'react';
import useStyles from './styles';

const GermanyFlag = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={useStyles().jobDoneIcon} {...props}>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.9932 1.33228H8.00648C4.32561 1.33228 1.33154 4.32634 1.33154 8.00653V13.994C1.33154 17.6741 4.32561 20.6682 8.00648 20.6682H13.9932C17.6734 20.6682 20.6675 17.6741 20.6675 13.994V8.00653C20.6682 4.32565 17.6734 1.33228 13.9932 1.33228Z' fill='#EE1B2E' />
      <path d='M20.6572 7.79203C20.5424 4.21153 17.6013 1.33228 13.9939 1.33228H8.0072C4.3992 1.33228 1.45807 4.21153 1.34326 7.79203H20.6572Z' fill='#282828' />
      <path d='M1.34277 14.2079C1.45759 17.7884 4.39871 20.6676 8.00671 20.6676H13.9935C17.6008 20.6676 20.5419 17.7884 20.6567 14.2079H1.34277Z' fill='#FDC301' />
    </svg>
  </span>
);

export default GermanyFlag;
